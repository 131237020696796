import React from "react"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import diagrams from "../img/*.png"
import ImgBg from "~/app/components/ImgBg"
import IconX from "~/app/components/IconX"
import background from "~/app/assets/images/bg.jpg"
import { texts } from '../LensRangeData'
import { gsap } from "gsap"

export default class extends React.Component {
  state = {
    currentLens: "F207",
    open: false,
    lenses: [
      { focalLength: "17", symbol: "F17" },
      { focalLength: "20.7", symbol: "F207" },
      { focalLength: "23.7", symbol: "F237" },
      { focalLength: "27", symbol: "F27" },
      { focalLength: "37", symbol: "F37" },
      { focalLength: "47", symbol: "F47" },
      { focalLength: "57", symbol: "F57" },
      { focalLength: "77", symbol: "F77" },
      { focalLength: "107", symbol: "F107" },
      { focalLength: "137", symbol: "F137" },
    ]
  }

  componentDidMount() {
    // preload diagram images
    let images = new Array()
    for (const key in diagrams) {
      images.push(diagrams[key])
    }

    this.loadImagesInSequence(images, diagrams)
  }

  loadImagesInSequence = (images, diagrams) => {
    if (!images.length) {
      return
    }

    // fade in the page after first image loads
    // if (images.length === Object.keys(diagrams).length - 1) {
    //   this.fadeInPage()
    // }

    let img = new Image(),
      url = images.shift()

    let that = this
    img.onload = function() {
      that.loadImagesInSequence(images, diagrams)
    }
    img.src = url
  }

  handleLengthChange = newLens => {
    let el = document.querySelectorAll(`.${styles.popup} > div`)
    gsap.killTweensOf(el)

    gsap.fromTo(el,
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 2.2,
        delay: 0
      })

    this.setState({
      open: !this.state.open,
      currentLens: newLens
    })
  }

  closePopup = () => {
    this.setState({ open: false })
  }

  // Iterate over lenses and display them
  displayLenses = (lenses) => {
    return (
      <div className={Cx(styles.lengths)}>
        {lenses.map((lens) => (
          <div
            key={lens.symbol}
            className={Cx(styles.length)}
            onClick={() => this.handleLengthChange(lens.symbol)}
          >
            <span><b>{lens.focalLength.slice(0, -1)}</b>7</span>MM
          </div>
        ))}
      </div>
    );
  }

  // Iterate over lenses' inspiration section text and display it if it's currently selected
  mappedLenses = (lenses, currentLens) => {
    return (
      <div>
        {lenses.map(lens => {
          if (lens.focalLength === currentLens) {
            return (
              <div key={lens.symbol} className={Cx(styles.inspiration)}>
                {lens.inspiration.length > 0 && (
                  <>
                    <div className={Cx(styles.inspirationHeader)}>INSPIRATION</div>
                    <ul>
                      {lens.inspiration.map(inspirationLine => {
                        return <li key={inspirationLine}>{inspirationLine}</li>;
                      })}
                    </ul>
                  </>
                )}
                <div className={Cx(styles.testimonial)}>
                  <div className={Cx(styles.testimonialQuote)}
                    dangerouslySetInnerHTML={{ __html: lens.testimonial.quote}}/>
                  <div className={Cx(styles.author)}>
                    <div className={Cx(styles.authorName)}>
                      {lens.testimonial.author.name}
                    </div>
                    <div className={Cx(styles.authorTitle)}>
                      {lens.testimonial.author.title}
                    </div>
                    <div className={Cx(styles.authorCompany)}>
                      {lens.testimonial.author.company}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  

  render() {
    const { lenses } = this.state

    // Find the current lens form lenses array
    const currentFocalLensObject = lenses.find(lens => lens.symbol === this.state.currentLens)
    // Trim the "7" off the lens focal length to display current lens at the top of the page
    const currentFocalLensTitle = currentFocalLensObject.focalLength.slice(0, -1)

    return (
      <div>
        <Navigation />
        <LogoTribe />
        <ImgBg
          src={background}
          className={styles.bg}
          readyClassName={styles.bgReady}
        />

        <div style={{paddingTop: '62px'}}>
          <div className={styles.header}>FOCAL LENGTH RANGE</div>
          <div className={styles.moreInfo}><span>*</span>TAP EACH FOR MORE INFO<span>*</span></div>
          {this.displayLenses(lenses)}
        </div>
        <div className={Cx(styles.popup, {[styles.open]: this.state.open})}>
          <div className={styles.popupInner}>
            <div className={styles.x} onClick={this.closePopup}><IconX/></div>
            <div className={Cx(styles.length)}>
              <span>
                <b>{currentFocalLensTitle}</b>7
              </span>
            MM
            </div>

            <div
              ref={c => {
                this._el = c
              }}
              className={Cx(styles.diagram)}
            >
              <img src={diagrams[this.state.currentLens]} />
            </div>
            <div
              ref={c => {
                this._text = c
              }}
              className={Cx(styles.diagramText)}
            >
              {this.mappedLenses(texts, this.state.currentLens)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
