import React from "react"
import { gsap } from "gsap"
import { CustomEase } from "gsap/CustomEase"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import diagrams from "../img/*.png"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import { texts } from '../LensRangeData'

export default class extends React.Component {
  state = {
    currentLens: "F17",
    lenses: [
      { focalLength: "17", symbol: "F17" },
      { focalLength: "20.7", symbol: "F207" },
      { focalLength: "23.7", symbol: "F237" },
      { focalLength: "27", symbol: "F27" },
      { focalLength: "37", symbol: "F37" },
      { focalLength: "47", symbol: "F47" },
      { focalLength: "57", symbol: "F57" },
      { focalLength: "77", symbol: "F77" },
      { focalLength: "107", symbol: "F107" },
      { focalLength: "137", symbol: "F137" },
    ]
  }

  componentDidMount() {
    // preload diagram images
    let images = new Array()
    for (var key in diagrams) {
      images.push(diagrams[key])
    }

    this.loadImagesInSequence(images, diagrams)
  }

  loadImagesInSequence = (images, diagrams) => {
    if (!images.length) {
      return
    }

    // fade in the page after first image loads
    if (images.length === Object.keys(diagrams).length - 1) {
      this.fadeInPage()
    }

    let img = new Image(),
      url = images.shift()

    let that = this
    img.onload = function() {
      that.loadImagesInSequence(images, diagrams)
    }
    img.src = url
  }

  handleLengthChange = newLens => {
    if (this.state.currentLens !== newLens) {
      gsap.killTweensOf(this._el)
      gsap.killTweensOf(this._text)

      gsap
        .timeline()
        .to(this._el, {
          opacity: 0,
          scaleY: 1.03,
          duration: 0.2,
          onComplete: this.onCompleteHandler,
          onCompleteParams: [newLens]
        })
        .to(
          this._text,
          {
            opacity: 0,
            duration: 0.12
          },
          "-=0.2"
        )
    }
  }

  onCompleteHandler = newLens => {
    this.setState({
      currentLens: newLens
    })
  }

  fadeInPage = () => {
    gsap.registerPlugin(CustomEase)
    gsap
      .fromTo(
        this._wrap,
        { opacity: 0 },
        {
          opacity: 0.7,
          duration: 2.6,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.09116,0.26782 0.15086,0.43074 0.15694,0.44733 0.16234,0.45808 0.17283,0.47169 0.19642,0.50228 0.21314,0.52234 0.24249,0.54956 0.31205,0.61403 0.35449,0.65299 0.43055,0.71057 0.51315,0.77311 0.56472,0.80796 0.65357,0.86077 0.72937,0.90584 0.77865,0.92848 0.85807,0.96473 0.88838,0.97856 0.90924,0.98412 0.94124,0.99208 0.96291,0.99746 1,1 1,1"
          )
        }
      )
  }

  fadeIn = () => {
    gsap.registerPlugin(CustomEase)

    if (this._el && this._text) {
      gsap
        .timeline()
        .fromTo(
          this._text,
          { opacity: 0 },
          {
            opacity: 0.7,
            duration: 2.6,
            ease: CustomEase.create(
              "custom",
              "M0,0 C0,0 0.09116,0.26782 0.15086,0.43074 0.15694,0.44733 0.16234,0.45808 0.17283,0.47169 0.19642,0.50228 0.21314,0.52234 0.24249,0.54956 0.31205,0.61403 0.35449,0.65299 0.43055,0.71057 0.51315,0.77311 0.56472,0.80796 0.65357,0.86077 0.72937,0.90584 0.77865,0.92848 0.85807,0.96473 0.88838,0.97856 0.90924,0.98412 0.94124,0.99208 0.96291,0.99746 1,1 1,1"
            )
          }
        )
        .fromTo(
          this._el,
          { opacity: 0, scaleY: 0.94 },
          {
            opacity: 1,
            scaleY: 1,
            duration: 10,
            ease: CustomEase.create(
              "custom",
              "M0,0,C0.028,0.398,0.035,0.658,0.226,0.762,0.44,0.878,0.818,1.001,1,1"
            )
          },
          "-=2.5"
        )
    }
  }

  // Iterate over lenses and display them
  displayLenses = (lenses) => {
    return (
      <div className={Cx(styles.lengths)}>
        {lenses.map((lens) => {
          return (
            <div
              className={Cx(styles.length, {
                [styles.lengthActive]: this.state.currentLens === lens.symbol
              })}
              onMouseEnter={() => this.handleLengthChange(lens.symbol)}
              key={lens.symbol}
            >
              <span><b>{lens.focalLength.slice(0, -1)}</b>7</span>MM
            </div>
          );
        })}
      </div>
    );
  }

  // Iterate over lenses' inspiration section text and display it if it's currently selected
  mappedLenses = (lenses, currentLens) => {
    return (
      <div>
        {lenses.map(lens => {
          if (lens.focalLength === currentLens) {
            return (
              <div key={lens.symbol} className={Cx(styles.inspiration)}>
                {lens.inspiration.length > 0 && (
                  <>
                    <div className={Cx(styles.inspirationHeader)}>INSPIRATION</div>
                    <ul>
                      {lens.inspiration.map(inspirationLine => {
                        return <li key={inspirationLine}>{inspirationLine}</li>;
                      })}
                    </ul>
                  </>
                )}
                {lens.testimonial && (
                  <div className={Cx(styles.testimonial)}>
                    <div className={Cx(styles.testimonialQuote)}
                      dangerouslySetInnerHTML={{ __html: lens.testimonial.quote}}/>
                    <div className={Cx(styles.author)}>
                      <div className={Cx(styles.authorName)}>
                        {lens.testimonial.author.name}
                      </div>
                      <div className={Cx(styles.authorTitle)}>
                        {lens.testimonial.author.title}
                      </div>
                      <div className={Cx(styles.authorCompany)}>
                        {lens.testimonial.author.company}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    );
  };

  
  render() {
    this.fadeIn()
    const { lenses, currentLens } = this.state

    return (
      <div>
        <Navigation />
        <LogoTribe />
        <ImgBg
          src={background}
          className={styles.bg}
          readyClassName={styles.bgReady}
        />

        <div
          className="section"
          style={{
            display: "table",
            tableLayout: "fixed",
            width: "100%",
            height: "100vh"
          }}
        >
          <div
            className={Cx(styles.diagramSectionOuter)}
            ref={c => {
              this._wrap = c
            }}
          >
            <div className={Cx(styles.diagramSectionInner)}>

              {this.displayLenses(lenses)}

              <div className={Cx(styles.pageBody)}>
                <div
                  ref={c => {
                    this._text = c
                  }}
                  className={Cx(styles.diagramText)}
                >
                  {this.mappedLenses(texts, currentLens)}
                </div>
                <div
                  ref={c => {
                    this._el = c
                  }}
                  className={Cx(styles.diagram)}
                >
                  <img src={diagrams[currentLens]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
